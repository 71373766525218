import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import ReportCard from './ReportCard';
import ProgressCircle from './ProgressCircle';
import { secondsToTime } from '../../utils/time';

const ScoreContaner = styled.div`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 0;
`;

const ScoreStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ScoreDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ScoreInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const ScoreInfoDetails = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

const ScoreInfoLabel = styled.span`
  color: #0E1E40;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
`;

const ScoreInfoStatus = styled.span`
  color: #293855;
  font-size: 12px;
  line-height: 20px;
`;

function DailyQualityCard({ qualityHours, qualitySessions, score }) {
  return (
    <ReportCard
      title="Daily Quality Time Stats"
      info="This is for test"
    >
      <ScoreContaner>
        <ScoreStatusWrapper>
          <ScoreInfoWrapper>
            <ScoreInfoDetails>
              <ScoreInfoLabel>{secondsToTime(qualityHours)}</ScoreInfoLabel>
              <ScoreInfoStatus>Quality hours</ScoreInfoStatus>
            </ScoreInfoDetails>
            <ScoreInfoDetails>
              <ScoreInfoLabel>{secondsToTime(qualitySessions)}</ScoreInfoLabel>
              <ScoreInfoStatus>Quality sessions</ScoreInfoStatus>
            </ScoreInfoDetails>
            <ScoreInfoDetails>
              <ScoreInfoLabel>{score / 100}</ScoreInfoLabel>
              <ScoreInfoStatus>Avg. quality time length</ScoreInfoStatus>
            </ScoreInfoDetails>
          </ScoreInfoWrapper>
          <ScoreDetails>
            <ProgressCircle
              percentage={score}
              text={score ? `${score}%` : '-'}
              textColor="#4469A1"
              pathColor="#4469A1"
              trailColor="#D8E6FC"
              textSize="24px"
            />
          </ScoreDetails>
        </ScoreStatusWrapper>
        <div className="d-flex">
          <div className="mr-1 progress-label">0%</div>
          <div className="flex-grow-1 position-relative">
            <div className="progress-value mb-1" style={{ marginLeft: `${score}%` }}>
              {`${score}%`}
            </div>
            <div className="engagement-progress-bar">
              <Tooltip
                placement="top"
                title="Organization average range: 45% - 55%"
                componentsProps={{
                  tooltip: {
                    sx: {
                      width: '150px',
                      textAlign: 'center',
                    },
                  },
                }}
              >
                <div className="middle-area" style={{ background: '#5A81BC' }} />
              </Tooltip>
              <Tooltip
                placement="top"
                title={`Team average: ${score}%`}
              >
                <div
                  className="value-bar"
                  style={{ left: `${score - 2}%` }}
                >
                  <div className="value-stick" />
                </div>
              </Tooltip>
            </div>
            <div className="progress-arrow" style={{ marginLeft: `${score}%` }} />
          </div>
          <div className="ml-1 progress-label">100%</div>
        </div>
      </ScoreContaner>
    </ReportCard>
  );
}

DailyQualityCard.propTypes = {
  score: PropTypes.number.isRequired,
  qualityHours: PropTypes.number.isRequired,
  qualitySessions: PropTypes.number.isRequired,
};

export default DailyQualityCard;
