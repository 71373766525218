import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ProgressCircle from './ProgressCircle';
import ReportCard from './ReportCard';
import EmptyBox from './EmptyBox';
import { UserIcon } from '../../components/Icon';

const AlertsContainer = styled.div`
  display: flex;
  padding: 8px 16px 16px;
  align-items: center;
`;

const AlertsDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 40%;
  border-right: 1px solid #E0E1E6;
`;

const ScoreInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ScoreInfoDetails = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ScoreInfoLabel = styled.span`
  color: #293855;
  font-size: 14px;
`;

const ScoreInfoStatus = styled.span`
  display: flex;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: ${(props) => (props.color)};
`;

const AlertsMembers = styled.div`
  display: flex;
  padding-left: 16px;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 60%;
`;

const AlertsItem = styled.div`
  display: flex;
  justify-content: space-between;
  aligin-items: center;
  padding: 0px 16px 8px 16px;
  border-bottom: 1px solid #E0E1E6;

  &:last-of-type {
    border-bottom: none;
  }
`;

const AlertsItemInfo = styled.span`
  color: #0E1E40;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;

const AlertStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 181px;
  justify-content: space-between;
`;

const AlertStatusBar = styled.span`
  width: 150px;
  height: 16px;
  border-radius: 10px;
  background: #F5C0B0;
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(150px * ${(props) => (props.percent)});
    height: 100%;
    background: #EB6240;
  }
`;

const AlertCount = styled.span`
  color: #293855;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;

function CriticalAlertsCard({
  hrBotAlerts,
  itBotAlerts,
  infosecBotAlerts,
  memberAlerts,
}) {
  const totalAlerts = hrBotAlerts + itBotAlerts + infosecBotAlerts;

  const calculatePercent = (count, totalCount) => {
    if (count === 0 || totalCount === 0) return 0;
    return Math.round((count * 100) / totalCount);
  };

  return (
    <ReportCard
      title="Critical Alerts"
      info="This is for test"
      link="/members/alerts"
    >
      <AlertsContainer>
        <AlertsDetails>
          <ProgressCircle
            percentage={totalAlerts}
            text={`${totalAlerts}`}
            textColor="#CC4525"
            pathColor="#CC4525"
            trailColor="#F5C0B0"
            textSize="24px"
          />
          <ScoreInfoWrapper>
            <ScoreInfoDetails>
              <ScoreInfoStatus color="#213D69" />
              <ScoreInfoLabel>
                {`${calculatePercent(hrBotAlerts, totalAlerts)}% HR Bot Alerts`}
              </ScoreInfoLabel>
            </ScoreInfoDetails>
            <ScoreInfoDetails>
              <ScoreInfoStatus color="#5A81BC" />
              <ScoreInfoLabel>
                {`${calculatePercent(itBotAlerts, totalAlerts)}% IT Bot Alerts`}
              </ScoreInfoLabel>
            </ScoreInfoDetails>
            <ScoreInfoDetails>
              <ScoreInfoStatus color="#B5BAC4" />
              <ScoreInfoLabel>
                {`${calculatePercent(infosecBotAlerts, totalAlerts)}% Infosec Bot Alerts`}
              </ScoreInfoLabel>
            </ScoreInfoDetails>
          </ScoreInfoWrapper>
        </AlertsDetails>
        <AlertsMembers>
          {memberAlerts.length === 0 ? (
            <EmptyBox message="There are no critical alerts at this moment!" />
          ) : (
            <>
              <AlertsItem>
                <AlertsItemInfo>Top Members</AlertsItemInfo>
                <AlertStatusWrapper>
                  <AlertsItemInfo>Alerts Distribution</AlertsItemInfo>
                </AlertStatusWrapper>
              </AlertsItem>
              {memberAlerts.map((member, key) => (
                <AlertsItem key={key}>
                  <div className="d-flex align-c gap-2">
                    <UserIcon width="24" height="24" color="#F3AF3D" />
                    <AlertsItemInfo>{member.userFullName}</AlertsItemInfo>
                  </div>
                  <AlertStatusWrapper>
                    <AlertCount>{member.alertsCount}</AlertCount>
                    <AlertStatusBar percent={member.alertsCount / totalAlerts} />
                  </AlertStatusWrapper>
                </AlertsItem>
              ))}
            </>
          )}
        </AlertsMembers>
      </AlertsContainer>
    </ReportCard>
  );
}

CriticalAlertsCard.propTypes = {
  hrBotAlerts: PropTypes.number.isRequired,
  itBotAlerts: PropTypes.number.isRequired,
  infosecBotAlerts: PropTypes.number.isRequired,
  memberAlerts: PropTypes.arrayOf(PropTypes.shape({
    //
  })).isRequired,
};

export default CriticalAlertsCard;
