import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../contexts/User';
import { accountTypeLevel } from '../constants/accountTypes';
import { NotificationContext } from '../contexts/Notification';

function useWorkspaces() {
  const history = useHistory();
  const { selectWorkspace, startTwoFactor } = useContext(UserContext);
  const { displayNotification } = useContext(NotificationContext);

  const handleWorkspaceSelection = async (workspace) => {
    const res = await selectWorkspace(workspace.id, workspace.member.user);
    if (res.success) {
      // eslint-disable-next-line no-nested-ternary
      const targetUrl = accountTypeLevel[workspace.member.accountType] >= accountTypeLevel['Workspace admin']
        ? 'dashboard'
        : accountTypeLevel[workspace.member.accountType] === accountTypeLevel['Team Leader']
          ? 'members/team-engagement' : 'pto/requests';
      if (res?.qrCode) {
        startTwoFactor({ qrCode: res.qrCode, twoFactorToken: res.twoFactorToken, redirect: targetUrl });
      } else if (res?.twoFactorToken) {
        startTwoFactor({ twoFactorToken: res.twoFactorToken, redirect: targetUrl });
      } else {
        history.push(`/${targetUrl}`);
      }
    } else {
      displayNotification({
        message: 'Sign in workspace failed.',
        severity: 'error',
        verticalAlign: 'top',
      });
    }
  };

  return { handleWorkspaceSelection };
}

export default useWorkspaces;
