import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, MenuItem, FormControl, InputLabel, Select,
} from '@mui/material';
import { BootstrapInput } from '../../components/muiCustomized/TextInput';

export default function TeamSelect({
  id, label, menuItems, menuWidth, value, handleForm, disabled,
}) {
  return (
    <FormControl fullWidth>
      { label ? (
        <InputLabel
          sx={{
            fontSize: '18px',
            color: '#212529',
            transform: 'translate(0, -1.5px) scale(0.75)',
          }}
          shrink
          htmlFor={id}
        >
          {label}
        </InputLabel>
      ) : null}
      <Select
        id={id}
        name={id}
        value={value}
        onChange={handleForm}
        disabled={disabled}
        input={(
          <BootstrapInput
            sx={{
              '& .MuiInputBase-input': {
                border: '1.5px solid',
                borderColor: 'var(--sds-input-border)',
              },
            }}
            background="#F9F9F9"
          />
          )}
        renderValue={(selected) => {
          if ((!selected && selected !== false) || selected === 0) {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                All Teams
              </Box>
            );
          }
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              { menuItems.find((item) => item.id === selected).name}
            </Box>
          );
        }}
        displayEmpty
      >
        <MenuItem
          value={0}
          className="flex-column align-items-start"
        >
          <Box sx={{
            color: '#061232',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
          }}
          >
            All
          </Box>
          <Box sx={{
            color: 'var(--dark-3, #546077)',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px',
            textWrap: 'wrap',
            maxWidth: menuWidth,
          }}
          >
            All Teams
          </Box>
        </MenuItem>
        {
          menuItems.map((item, index) => (
            <MenuItem
              key={`${id}-${index}`}
              value={item.id}
              className="flex-column align-items-start"
            >
              <Box sx={{
                color: '#061232',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
              }}
              >
                {item.name}
              </Box>
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}

TeamSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  menuWidth: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  handleForm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
