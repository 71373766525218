import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import ReportCard from './ReportCard';
import ProgressCircle from './ProgressCircle';

const ScoreContaner = styled.div`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 0;
`;

const ScoreStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ScoreDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ScoreStatus = styled.p`
  color: #293855;
  font-size: 14px;
  font-weight: 500;
`;

const ScoreInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`;

const ScoreInfoDetails = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ScoreInfoLabel = styled.span`
  color: #293855;
  font-size: 14px;
`;

const ScoreInfoStatus = styled.span`
  display: flex;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: ${(props) => (props.color)};
`;

function OverallScoreCard({ score }) {
  const getScoreStatus = (score) => {
    if (score === 0) return '-';
    if (score > 0 && score < 21) return 'Low Activity';
    if (score > 20 && score < 51) return 'Focused';
    if (score > 50) return 'Deep Focus';
    return null; // Default case if score is undefined or invalid
  };

  return (
    <ReportCard
      title="Overall Engagement Score"
      info="This is for test"
    >
      <ScoreContaner>
        <ScoreStatusWrapper>
          <ScoreDetails>
            <ProgressCircle
              percentage={score}
              text={score ? `${score}%` : '-'}
              textColor="#53A551"
              pathColor="#53A551"
              trailColor="#ECECF0"
              textSize="24px"
            />
            <ScoreStatus>{getScoreStatus(score)}</ScoreStatus>
          </ScoreDetails>
          <ScoreInfoWrapper>
            <ScoreInfoDetails>
              <ScoreInfoLabel>Deep Focus (51% - 100%)</ScoreInfoLabel>
              <ScoreInfoStatus color="#213D69" />
            </ScoreInfoDetails>
            <ScoreInfoDetails>
              <ScoreInfoLabel>Focused (21% - 50%)</ScoreInfoLabel>
              <ScoreInfoStatus color="#5A81BC" />
            </ScoreInfoDetails>
            <ScoreInfoDetails>
              <ScoreInfoLabel>Low Activity (1% - 20%)</ScoreInfoLabel>
              <ScoreInfoStatus color="#B5BAC4" />
            </ScoreInfoDetails>
          </ScoreInfoWrapper>
        </ScoreStatusWrapper>
        <div className="d-flex">
          <div className="mr-1 progress-label">0%</div>
          <div className="flex-grow-1 position-relative">
            <div className="progress-value mb-1" style={{ marginLeft: `${score}%` }}>
              {`${score}%`}
            </div>
            <div className="engagement-progress-bar">
              <Tooltip
                placement="top"
                title="Organization average range: 45% - 55%"
                componentsProps={{
                  tooltip: {
                    sx: {
                      width: '150px',
                      textAlign: 'center',
                    },
                  },
                }}
              >
                <div className="middle-area" />
              </Tooltip>
              <Tooltip
                placement="top"
                title={`Team average: ${score}%`}
              >
                <div
                  className="value-bar"
                  style={{ left: `${score - 2}%` }}
                >
                  <div className="value-stick" />
                </div>
              </Tooltip>
            </div>
            <div className="progress-arrow" style={{ marginLeft: `${score}%` }} />
          </div>
          <div className="ml-1 progress-label">100%</div>
        </div>
      </ScoreContaner>
    </ReportCard>
  );
}

OverallScoreCard.propTypes = {
  score: PropTypes.number.isRequired,
};

export default OverallScoreCard;
