import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Button, Box, Grid, FormControl, TextField, Autocomplete, FormLabel,
} from '@mui/material';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';

const FORM_DEFAULT = {
  email: '', accountType: '', department: '', designation: '',
};

const deparments = [
  { label: 'Others' },
  { label: 'Contractors' },
  { label: 'Product Development' },
  { label: 'Development' },
  { label: 'BPO' },
  { label: 'Izenda' },
  { label: 'ChartRequest' },
  { label: 'Full Cycle' },
];

const designations = [
  { label: 'Others' },
  { label: 'Software Developer' },
  { label: 'Senior Data Scientist' },
  { label: 'Team Lead' },
  { label: 'Business Process Analyst' },
  { label: 'BI Analyst' },
  { label: 'Customer Success Associate' },
  { label: 'Software Developer II' },
  { label: 'QA Lead' },
  { label: 'Business Analyst' },
  { label: 'Junior Software Developer' },
  { label: 'Senior Business Analyst' },
  { label: 'QA Manager' },
  { label: 'HR' },
];

const accountTypes = [
  { label: 'Team member' },
  { label: 'Team Leader' },
  { label: 'Workspace owner' },
  { label: 'Workspace admin' },
];

function NewMemberModal({ closeModal, getMembers, reportingManagers }) {
  const { activeWorkspace } = useContext(UserContext);
  const [formData, setFormData] = useState(FORM_DEFAULT);
  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState();

  const handleForm = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleAddMember = async () => {
    setFormErrors({});
    const response = await sendRequest({
      method: 'post',
      url: API.WORKSPACE.member(activeWorkspace.id),
      data: formData,
    });
    if (response.success) {
      setSuccessMessage(`Created new member. Invitation sent to ${formData.email}`);
      setFormData(FORM_DEFAULT);
      getMembers();
    } else {
      setFormErrors(response.data);
    }
  };

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c" data-testid="new-member-modal">
      <Box style={{
        width: 588,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <h5 className="m-b16 text-600" data-testid="modal-title">Add a New Workspace Member</h5>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel
                sx={{
                  color: '#293855',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                Email Address
              </FormLabel>
              <TextField
                required
                name="email"
                placeholder="Required"
                aria-describedby="my-helper-text"
                value={formData?.email}
                onChange={(event) => handleForm(event.target.name, event.target.value)}
                type="email"
                error={!!formErrors?.email}
                helperText={formErrors?.email}
                data-testid="email"
                inputProps={{
                  sx: {
                    '&::placeholder': {
                      color: '#7D8698',
                    },
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel
                sx={{
                  color: '#293855',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                Account Type
              </FormLabel>
              <Autocomplete
                disablePortal
                id="combo-box-accountTypes"
                name="accountType"
                onInputChange={(event, newInputValue) => {
                  handleForm('accountType', newInputValue);
                }}
                options={accountTypes}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    value={formData.accountType}
                    placeholder="Required"
                    data-testid="account-type"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                value={
                    reportingManagers.find((m) => m.id === formData.reportingManager) || null
                  }
                onChange={(e, newValue) => {
                  setFormData({ ...formData, reportingManager: newValue?.id || '' });
                }}
                options={reportingManagers}
                getOptionLabel={(option) => (`${option.user.firstName} ${option.user.lastName}`) || ''}
                renderInput={(params) => (
                  <TextField
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...params}
                    label="Reporting To"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <FormLabel
                sx={{
                  color: '#293855',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                Department
              </FormLabel>
              <Autocomplete
                disablePortal
                id="combo-box-departments"
                name="department"
                onInputChange={(event, newInputValue) => {
                  handleForm('department', newInputValue);
                }}
                options={deparments}
                sx={{ width: 254 }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    name="department"
                    value={formData.department}
                    placeholder="Department"
                    data-testid="department"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <FormLabel
                sx={{
                  color: '#293855',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                Designation
              </FormLabel>
              <Autocomplete
                disablePortal
                id="combo-box-designations"
                name="designation"
                onInputChange={(event, newInputValue) => {
                  handleForm('designation', newInputValue);
                }}
                options={designations}
                sx={{ width: 254 }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    name="designation"
                    value={formData.designation}
                    placeholder="Designation"
                    data-testid="designation"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className="newMemberForm-btns" style={{ marginTop: 24 }}>
          <Button
            onClick={closeModal}
            color="secondary"
            sx={{
              marginRight: 2,
              border: '1px solid #7D8698',
              width: 85,
              textTransform: 'none',
            }}
            data-testid="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleAddMember}
            sx={{
              width: 131,
              textTransform: 'none',
            }}
            disabled={!(formData?.email && formData?.accountType)}
            data-testid="add-member"
          >
            Add Member
          </Button>
        </div>
        <div className="success-msg">{successMessage}</div>
      </Box>
    </Modal>
  );
}

NewMemberModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  getMembers: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  reportingManagers: PropTypes.array.isRequired,
};

export default NewMemberModal;
