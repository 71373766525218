import moment from 'moment-timezone';

export const roundNearestMinute = (x) => (Math.round(x / 60)) * 60;

export const secondsToTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  return formattedTime;
};

// Takes seconds and formats to HH:MM:SS. Default without passing options object is HH:MM
export const formatSeconds = (
  secondsToFormat,
  { showSeconds = false, roundToNearestMinute = true } = {},
) => {
  const seconds = roundToNearestMinute ? roundNearestMinute(secondsToFormat) : secondsToFormat;
  if (showSeconds) {
    const date = new Date(seconds * 1000).toISOString().slice(11, 19);
    if (date.charAt(0) === '0') return date.substring(1);
    return date;
  }
  const date = secondsToTime(seconds);
  return date;
};

// Takes a moment date and returns Sunday - Saturday week range moment dates
export const getWeekRange = (momentDate) => {
  const start = moment(momentDate).startOf('week');
  const end = moment(momentDate).endOf('week');
  return { start, end };
};

export const getDatesInRange = (startDate, endDate) => {
  let start = new Date(startDate);
  const dateArr = [];
  while (start <= endDate) {
    dateArr.push(moment(start).format('MM/DD/YYYY'));
    const newDate = start.setDate(start.getDate() + 1);
    start = new Date(newDate);
  }
  return dateArr;
};

export const convertSecToTimeFormat = (sec) => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - (hours * 3600)) / 60);
  const seconds = sec - (hours * 3600) - (minutes * 60);
  return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

/**
 * @param {function} callback - the callback to fire when the timeout ends
 * @param {number} milliseconds - the number of ms before the timeout will execute
 * @description When a user's computer goes to sleep, a normal setTimeout will pause which can cause
 * the timeout to execute later after the computer wakes up. 'setStrictTimeout' will execute the callback
 * immediately if the computer wakes and the 'milliseconds' have passed
 */
export const setStrictTimeout = (callback, milliseconds) => {
  const executeTimeoutAtDate = Date.now() + milliseconds;
  const interval = setInterval(() => {
    if (Date.now() >= executeTimeoutAtDate) {
      clearInterval(interval);
      callback();
    }
  }, 1000);
  return interval;
};

export const formatTime = (dateString) => {
  const finalFormat = moment(dateString, 'YYYY-MM-DD HH:mm:ss').format('MMMM DD - hh:mm A');
  return finalFormat;
};

export const formatMomentTimezone = (timezone) => timezone?.replace('/', ', ')?.replace('_', ' ');

export const getTimeString = (value) => {
  const hours = Math.floor(value / 60) < 10 ? `0${Math.floor(value / 60)}` : Math.floor(value / 60);
  const minutes = value % 60 < 10 ? `0${value % 60}` : value % 60;
  const timeString = `${hours}:${minutes}`;
  return timeString;
};

/**
 * @param {string} timeString - time string value to be made to date object e.g "14:45:00" || HH:MM:SS
 * @description Function to create a time string to a proper date object for moment and components
 * using correctly typed Date object
 * @returns {Date | string}
 */
export const timeToDateObject = (timeString) => {
  if (typeof timeString === 'string') {
    const [hh, mm, ss] = timeString.split(':');
    if (!hh || !mm) {
      return timeString;
    }
    const baseDate = new Date();
    baseDate.setHours(hh);
    baseDate.setMinutes(mm);
    if (ss) {
      baseDate.setSeconds(ss);
    }
    return baseDate;
  }
  return timeString;
};

export const convertDateToTimeStr = (timestamp) => {
  const date = new Date(timestamp);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;

  const formattedTime = `${hours}:${minutesStr} ${ampm}`;
  return formattedTime;
};

export const timeToUTCDateObject = (timeString, utc = false) => {
  if (typeof timeString === 'string') {
    const [hh, mm, ss] = timeString.split(':');
    if (!hh || !mm) {
      return timeString; // Return as is if timeString format is invalid
    }

    // Parse the time string based on the `utc` flag
    let time;
    if (utc) {
      time = moment.utc(`${hh}:${mm}:${ss || '00'}`, 'HH:mm:ss'); // Default seconds to '00' if not provided
    } else {
      time = moment(`${hh}:${mm}:${ss || '00'}`, 'HH:mm:ss');
    }

    // Return the moment object as a Date object (UTC or local as per flag)
    return time.toDate();
  }

  return timeString; // If it's not a string, return the value as it is
};

/**
 * Calculate the date range, ensuring the start date is not earlier than the first day of the current month.
 *
 * @param {Date} today - The current date.
 * @param {number} daysBack - Number of days to subtract for the start date.
 * @returns {[Date, Date]} - An array containing the adjusted start date and the end date (today).
 */
export const currentMonthWeekRange = (daysBack = 6) => {
  const today = new Date();
  const startOfMonth = moment(today).startOf('month').toDate(); // First day of the current month
  const calculatedStartDate = moment(today).subtract(daysBack, 'days').toDate(); // Calculated start date

  // Adjust start date if it's earlier than the start of the current month
  const adjustedStartDate = calculatedStartDate < startOfMonth ? startOfMonth : calculatedStartDate;

  return [adjustedStartDate, today];
};

export const convertUtcTimeToTimezone = (utcTime, timezone) => moment
  .utc(utcTime, 'HH:mm') // Treat the input as UTC
  .tz(timezone) // Convert to the specified timezone
  .format('HH:mm');

export const timeToSeconds = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  return (hours * 3600) + (minutes * 60);
};

export const timeToDate = (dateString) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${formattedMonth}/${formattedDay}/${year}`;
};

export const formatDate = (date) => moment(date).format('MM/DD/YYYY');
export const isTimeRangeWithin = (start1, end1, start2, end2) => {
  const now = moment().utc();
  const dateStr = now.format('YYYY-MM-DD');
  const createTime = (time) => moment.tz(`${dateStr} ${time}`, 'YYYY-MM-DD HH:mm:ss', 'UTC');

  const startTime1 = createTime(start1);
  const endTime1 = createTime(end1);

  if (endTime1.isBefore(startTime1)) {
    endTime1.add(1, 'day');
  }

  const startTime2 = createTime(start2);
  const endTime2 = createTime(end2);

  if (endTime2.isBefore(startTime2)) {
    endTime2.add(1, 'day');
    return startTime1.isSameOrBefore(startTime2) && endTime1.isSameOrAfter(endTime2);
  }

  return (startTime1.isSameOrBefore(startTime2) && endTime1.isSameOrAfter(endTime2))
    || (startTime1.isSameOrBefore(startTime2.add(1, 'day')) && endTime1.isSameOrAfter(endTime2.add(1, 'day')));
};

// Function to calculate the count of weekdays (Monday to Friday) in a date range
export const countWeekdaysInRange = (startDate, endDate) => {
  let count = 0;
  const currentDate = moment(startDate);

  // Loop through the date range
  while (currentDate.isSameOrBefore(endDate)) {
    const dayOfWeek = currentDate.isoWeekday(); // 1 (Monday) to 7 (Sunday)
    if (dayOfWeek >= 1 && dayOfWeek <= 5) { // Check if the day is a weekday (Mon-Fri)
      count += 1;
    }
    currentDate.add(1, 'day'); // Move to the next day
  }

  return count;
};
