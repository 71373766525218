import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import styled from '@emotion/styled';
import ReportCard from './ReportCard';
import EmptyBox from './EmptyBox';
import { AlertIcon, ClockIcon, UserIcon } from '../../components/Icon';
import { convertSecToTimeFormat } from '../../utils/time';

const ShiftCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #E0E1E6;
  background: #FFF;
  box-shadow: 0px 7px 48px 0px rgba(0, 56, 153, 0.06);
  overflow: hidden;
  width: calc((100% - 32px) / 3);
`;

const ShiftCardHeader = styled.div`
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid #E0E1E6;
  background: #FBFBFC;
`;

const ShiftCardTitle = styled.h6`
  color: #0E1E40;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
`;

const ShiftCardDetails = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #546077;
  font-size: 12px;
  line-height: 16px;
`;

const ShiftCardCol = styled.span`
  display: flex;
  padding: 0px 16px 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E0E1E6;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #293855;

  &:last-of-type {
    border-bottom: none;
  }
`;

const UserDetail = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #0E1E40;
`;

function MajorShiftsCard({ members, workDays }) {
  const [lowWorkMembers, setLowWorkMembers] = useState([]);
  const [lowActiviyMembers, setLowActivityMembers] = useState([]);
  const [highActivityMembers, setHighActivityMembers] = useState([]);

  const getLowWorkMembers = () => {
    const data = members
      .filter((item) => (item.member.totalSecondsElapsed / workDays) < 28800)
      .sort((a, b) => a.member.totalSecondsElapsed - b.member.totalSecondsElapsed)
      .slice(0, 5);

    return data;
  };

  const getLowActiviyMembers = () => {
    const data = members
      .filter((item) => item.member.engagementScore < 35)
      .sort((a, b) => a.member.engagementScore - b.member.engagementScore)
      .slice(0, 5);

    return data;
  };

  const getHighActivityMembers = () => {
    const data = members
      .filter((item) => item.member.engagementScore > 90)
      .sort((a, b) => a.member.engagementScore - b.member.engagementScore)
      .slice(0, 5);

    return data;
  };

  useEffect(() => {
    if (members.length) {
      setLowWorkMembers(getLowWorkMembers());
      setLowActivityMembers(getLowActiviyMembers());
      setHighActivityMembers(getHighActivityMembers());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  return (
    <div className="d-flex w-100 gap-3">
      { lowWorkMembers.length || lowActiviyMembers.length || highActivityMembers.length ? (
        <>
          { !!lowWorkMembers.length && (
            <ShiftCard>
              <ShiftCardHeader>
                <ShiftCardTitle>Low Work Time Alerts</ShiftCardTitle>
                <ShiftCardDetails>
                  <ClockIcon width="16" height="16" color="#5A81BC" />
                  <span>Work time below 08:00 hr/day</span>
                </ShiftCardDetails>
                <ShiftCardDetails>
                  <AlertIcon width="16" height="16" color="#5A81BC" />
                  <span>This Week</span>
                </ShiftCardDetails>
              </ShiftCardHeader>
              { lowWorkMembers.map((member, key) => (
                <ShiftCardCol key={key}>
                  <UserDetail>
                    <UserIcon width="24" height="24" color="#EE7C5C" />
                    <span>{member.member.user.fullName}</span>
                  </UserDetail>
                  <span>
                    {`${convertSecToTimeFormat(Math.ceil(member.member.totalSecondsElapsed / workDays))} hr/day`}
                  </span>
                </ShiftCardCol>
              )) }
            </ShiftCard>
          )}
          { !!lowActiviyMembers.length && (
            <ShiftCard>
              <ShiftCardHeader>
                <ShiftCardTitle>Members with Low Activity</ShiftCardTitle>
                <ShiftCardDetails>
                  <ClockIcon width="16" height="16" color="#5A81BC" />
                  <span>Weekly activity rate under 35%</span>
                </ShiftCardDetails>
                <ShiftCardDetails>
                  <AlertIcon width="16" height="16" color="#5A81BC" />
                  <span>This Week</span>
                </ShiftCardDetails>
              </ShiftCardHeader>
              { lowActiviyMembers.map((member, key) => (
                <ShiftCardCol key={key}>
                  <UserDetail>
                    <UserIcon width="24" height="24" color="#EE7C5C" />
                    <span>{member.member.user.fullName}</span>
                  </UserDetail>
                  <span>{`${member.member.engagementScore}%`}</span>
                </ShiftCardCol>
              )) }
            </ShiftCard>
          )}
          { !!highActivityMembers.length && (
            <ShiftCard>
              <ShiftCardHeader>
                <ShiftCardTitle>High Activity Spikes</ShiftCardTitle>
                <ShiftCardDetails>
                  <ClockIcon width="16" height="16" color="#5A81BC" />
                  <span>Weekly activity rate above 90%</span>
                </ShiftCardDetails>
                <ShiftCardDetails>
                  <AlertIcon width="16" height="16" color="#5A81BC" />
                  <span>This Week</span>
                </ShiftCardDetails>
              </ShiftCardHeader>
              { highActivityMembers.map((member, key) => (
                <ShiftCardCol key={key}>
                  <UserDetail>
                    <UserIcon width="24" height="24" color="#EE7C5C" />
                    <span>{member.member.user.fullName}</span>
                  </UserDetail>
                  <span>{`${member.member.engagementScore}%`}</span>
                </ShiftCardCol>
              )) }
            </ShiftCard>
          )}
        </>
      ) : (
        <ReportCard>
          <EmptyBox message="There are no major shifts in engagement at this moment!" />
        </ReportCard>
      )}
    </div>
  );
}

MajorShiftsCard.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({
  })),
  workDays: PropTypes.number.isRequired,
};

export default MajorShiftsCard;
