import React, {
  useState, useEffect, useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Button, MenuItem, css } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { isEmail } from 'validator';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { TextInput } from '../../components/muiCustomized/TextInput';
import { Dropdown } from '../../components/muiCustomized/Dropdown';
import FormLayout from './FormLayout';
import { FormDataType } from '../../propTypes';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';

const autofillStyle = (props) => css`
    background-color: ${props.isInputAutoFilled && '#E8F0FE !important'};
  `;

const InviteMembersInput = styled.div`
  ${autofillStyle}
  display: flex;
  align-items: center;
  border: 1px solid #E0E1E6;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  &:focus-within {
    box-shadow: rgba(66, 146, 86, 0.25) 0 0 0 0.2rem;
    background-color: #fff;
    border-color: #429256;
  },
  & .MuiInputBase-input {
    border: 0 !important;
    box-shadow: none !important;
  }
  & .MuiFormControl-root {
    margin: 0 !important;
  }
`;

function InviteTeam({
  formData, setFormData, currentStepVal,
}) {
  const { activeWorkspace } = useContext(UserContext);
  const history = useHistory();
  const invitedEmailsRef = useRef(null);
  const inviteMembersInputRef = useRef(null);
  const inviteBtnRef = useRef(null);
  const [formErrors, setFormErrors] = useState({});
  const [memberEmail, setMemberEmail] = useState('');
  const [accountType, setAccountType] = useState('Team member');
  const [selectedMember, setSelectedMember] = useState(null);
  const [isInputAutoFilled, setIsInputAutoFilled] = useState(false);

  const checkEmailValidity = (email) => {
    if (!isEmail(email)) return 'Invalid email';
    if (formData.workspaceMembers.some((member) => member.email === email)) return 'Email is already invited';
    return null;
  };

  const handleInvite = () => {
    setFormErrors({});
    if (checkEmailValidity(memberEmail)) return setFormErrors({ emailValidity: checkEmailValidity(memberEmail) });
    setFormData({
      ...formData,
      workspaceMembers: [...formData.workspaceMembers, { email: memberEmail, accountType }],
    });
    setMemberEmail('');
  };

  const handleRemoveMember = () => {
    setFormData({
      ...formData,
      workspaceMembers: formData.workspaceMembers.filter((member) => member.email !== selectedMember.email),
    });
    setSelectedMember(null);
  };

  const handleChangeEmail = (e) => {
    setFormErrors({});
    setMemberEmail(e.target.value);
  };

  const handleFinish = async () => {
    const response = await sendRequest({
      method: 'post',
      url: API.WORKSPACE.members(activeWorkspace.id),
      data: {
        members: formData.workspaceMembers,
      },
    });
    if (response.success) {
      history.push('/dashboard');
    } else {
      return setFormData({ ...formData, hasError: true });
    }
  };

  const handleAutofill = (e) => {
    if (e.animationName === 'onAutoFillStart') {
      setIsInputAutoFilled(true);
    } else if (e.animationName === 'onAutoFillCancel') {
      setIsInputAutoFilled(false);
    }
  };

  useEffect(() => {
    function _handleClickOutside(event) {
      if (invitedEmailsRef.current && !invitedEmailsRef.current.contains(event.target)) {
        setSelectedMember(null);
      }
    }
    document.addEventListener('mousedown', _handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', _handleClickOutside);
    };
  }, [selectedMember]);

  return (
    <FormLayout
      heading="Your workspace is complete!"
      subheading="Invite your team or continue to your new workspace"
      currentStep={currentStepVal}
    >
      <div className="selectStorage-content">
        <p className="text-600 text-center">Managers vs Members</p>
        <p className="text-center m-t4" style={{ color: '#546077' }}>
          Workspace managers are able to view activity from anyone within the workspace via the admin dashboard.
        </p>
        <p className="text-center text-600" style={{ color: '#546077' }}>
          Members can record their own activity but cannot view work from other members.
        </p>
      </div>
      <div className="inviteMembers-input">
        <InviteMembersInput isInputAutoFilled={isInputAutoFilled} ref={inviteMembersInputRef}>
          <TextInput
            name="email"
            value={memberEmail}
            onChange={handleChangeEmail}
            onKeyDown={(e) => {
              const key = e.keyCode || e.which;
              if (key === 13) {
                e.preventDefault();
                inviteBtnRef.current.click();
              }
            }}
            inputProps={{
              onFocus: () => inviteMembersInputRef.current.focus(),
              onBlur: () => inviteMembersInputRef.current.blur(),
              onAnimationStart: handleAutofill,
            }}
            placeholder="Enter Email"
          />
          <div className="accountType-dropdown">
            <Dropdown
              sx={{ color: '#4469A1' }}
              value={accountType}
              setValue={setAccountType}
              name="account-type"
            >
              <MenuItem dense value="Workspace owner">Workspace owner</MenuItem>
              <MenuItem dense value="Workspace admin">Workspace admin</MenuItem>
              <MenuItem dense value="Team Leader">Team Leader</MenuItem>
              <MenuItem dense value="Team member">Team member</MenuItem>
            </Dropdown>
          </div>
        </InviteMembersInput>
        <Button
          ref={inviteBtnRef}
          variant="outlined"
          onClick={handleInvite}
          data-testid="invite"
        >
          Invite
        </Button>
      </div>
      <div style={{ position: 'relative', width: '100%', margin: '8px 0 16px' }}>
        <p className="text-danger" style={{ position: 'absolute', left: 12 }} data-testid="invalid-email">
          {formErrors?.emailValidity}
        </p>
      </div>
      <div ref={invitedEmailsRef} className="invited-emails" data-testid="invited-emails">
        {formData.workspaceMembers.map((member, index) => (
          <button
            type="button"
            key={member.email}
            className={`invited-email ${member.email === selectedMember?.email && 'selected'}`}
            onClick={() => setSelectedMember(member)}
            data-testid={`invited-email-${index + 1}`}
          >
            <p className="size-xs">{member.email}</p>
            {!(member.email === selectedMember?.email)
              ? <p className="size-xs" style={{ color: '#546077' }}>{member.accountType}</p>
              : (
                <button
                  type="button"
                  className="x-btn"
                  style={{ color: '#546077', backgroundColor: 'transparent' }}
                  onClick={handleRemoveMember}
                  data-testid="close"
                >
                  { }
                </button>
              )}
          </button>
        ))}
      </div>
      <Button
        fullWidth
        variant="contained"
        className="nextStep-btn m-t16"
        disabled={!(formData?.workspaceName && formData?.workspaceDescription)}
        onClick={handleFinish}
        data-testid="finish-creating-workspace"
      >
        Finish Creating Workspace
      </Button>
    </FormLayout>
  );
}

InviteTeam.propTypes = {
  formData: FormDataType,
  setFormData: PropTypes.func.isRequired,
  currentStepVal: PropTypes.number.isRequired,
};

export default InviteTeam;
