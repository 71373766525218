import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Dialog, DialogContent,
} from '@mui/material';
import { CalendarIcon, InfoIcon, AlertIcon } from '../../components/Icon';
import RefreshIcon from '../../images/refresh.svg';
import AssignmentIcon from '../../images/icon-assignment.svg';
import { formatDate } from '../../utils/time';

function ViewIssueModal({
  selectedAlert, isOpen, isUpdating, handleClose, handleMarkAsAcknowledged,
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="sm"
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '486px',
          margin: 'auto',
        },
      }}
      fullWidth
    >
      <DialogContent>
        <div className="alert-modal-container">
          <div className="d-flex align-c gap-2 mb-4">
            <span className="alert-status-badge" data-status={selectedAlert?.status}>
              {`${selectedAlert?.alertType?.category} Bot Alert - ${selectedAlert?.status}`}
            </span>
            <span className="alert-date-badge">
              <CalendarIcon width="20" height="20" />
              {selectedAlert?.createdAt ? formatDate(selectedAlert?.createdAt) : ''}
            </span>
          </div>
          <div className="d-flex align-c gap-3 mb-3">
            <div className="alert-avatar">
              {selectedAlert?.member?.user?.profilePicture ? (
                <img
                  src={selectedAlert?.member?.user?.profilePicture}
                  alt={selectedAlert?.member?.user?.fullName}
                  className="alert-avatar"
                />
              ) : (
                <span className="alert-avatar-letter">
                  {selectedAlert?.member?.user?.firstName.slice(0, 1)}
                  {selectedAlert?.member?.user?.lastName.slice(0, 1)}
                </span>
              )}
            </div>
            <div className="alert-user-info">
              <h5 className="alert-user-name">{selectedAlert?.member?.user?.fullName}</h5>
              <div className="d-flex gap-2">
                <Link to={`/members/alerts/${selectedAlert?.memberId}`} className="alert-modal-view-btn">
                  View Alerts
                </Link>
                <Link
                  to={`/members/activity/view?member=${selectedAlert?.memberId}`}
                  className="alert-modal-view-btn"
                >
                  View Engagement
                </Link>
              </div>
            </div>
          </div>
          <div className="d-flex space-between pb-4 border-bottom-div">
            <div>
              <div className="alert-user-info-title mb-1">Role</div>
              <div className="alert-user-info-content">{selectedAlert?.member?.user?.role}</div>
            </div>
          </div>
          <div className="pt-4">
            <div className="d-flex gap-2 mb-3">
              <div className="alert-icon p-2" style={{ backgroundColor: '#FEFBE7' }}>
                <InfoIcon width="24" height="24" color="#F19E41" />
              </div>
              <div>
                <div className="alert-user-info-title mb-1">Description/ Alert Triggered</div>
                <div className="alert-description-text">{selectedAlert?.alertType?.triggerCondition}</div>
              </div>
            </div>
            <div className="d-flex gap-2 mb-3">
              <div className="alert-icon p-2" style={{ backgroundColor: '#FCECE7' }}>
                <AlertIcon width="24" height="24" color="#EB6240" />
              </div>
              <div>
                <div className="alert-user-info-title mb-1">
                  { selectedAlert?.status === 'Pending' ? 'Action Taken' : 'Automated Action' }
                </div>
                <div className="alert-description-text">
                  { selectedAlert?.status === 'Pending' ? 'Acknowledged Alert' : 'Requested Acknowledgement' }
                </div>
              </div>
            </div>
            { selectedAlert?.status === 'Pending' && (
              <div className="d-flex gap-2 mb-3">
                <div className="alert-icon p-2" style={{ backgroundColor: 'rgba(207, 246, 206, 0.80)' }}>
                  <img src={AssignmentIcon} alt="refresh" style={{ height: 24 }} />
                </div>
                <div>
                  <div className="alert-user-info-title mb-1">Acknowledgement</div>

                  <div className="d-flex">
                    <div className="alert-description-text mr-2">1.</div>
                    <div>
                      <div className="alert-description-text mb-1">
                        This alert helps me stay accountable to my team.
                      </div>
                      <div className="alert-user-info-title mb-1">Strongly Agree</div>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="alert-description-text mr-2">2.</div>
                    <div>
                      <div className="alert-description-text mb-1">
                        Receiving this alert makes me feel more connected to my team goals.
                      </div>
                      <div className="alert-user-info-title mb-1">Strongly Agree</div>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="alert-description-text mr-2">3.</div>
                    <div>
                      <div className="alert-description-text mb-1">
                        This alert helps me reflect on my work habits and productivity.
                      </div>
                      <div className="alert-user-info-title mb-1">Strongly Agree</div>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="alert-description-text mr-2">4.</div>
                    <div>
                      <div className="alert-description-text mb-1">
                        This alert encourages me to stay on track with my tasks.
                      </div>
                      <div className="alert-user-info-title mb-1">Strongly Agree</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          { selectedAlert?.status === 'Acknowledged' && (
            <div className="d-flex pt-4">
              <button className="alert-resend-btn" type="button">
                <img src={RefreshIcon} alt="refresh" style={{ height: 20 }} />
                Resend Acknowledgement
              </button>
            </div>
          )}
          { selectedAlert?.status === 'Pending' && (
            <div className="d-flex pt-4">
              <button
                className="alert-mark-btn"
                type="button"
                disabled={isUpdating}
                onClick={() => handleMarkAsAcknowledged()}
              >
                { !isUpdating ? 'Mark as Acknowledged' : 'Updating...' }
              </button>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

ViewIssueModal.propTypes = {
  selectedAlert: PropTypes.shape({
    status: PropTypes.string.isRequired,
    alertType: PropTypes.shape({
      category: PropTypes.string.isRequired,
      triggerCondition: PropTypes.string.isRequired,
    }),
    createdAt: PropTypes.instanceOf(Date).isRequired,
    member: PropTypes.shape({
      user: PropTypes.shape({
        profilePicture: PropTypes.string,
        fullName: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        role: PropTypes.string,
      }),
    }),
    memberId: PropTypes.number.isRequired,

  }).isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleMarkAsAcknowledged: PropTypes.func.isRequired,
};

export default ViewIssueModal;
