import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import {
  Button, Menu,
} from '@mui/material';
import { UnfoldMore } from '@mui/icons-material/';
import CustomTable from '../../components/table/CustomTable';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import MultiSelect from '../../components/muiCustomized/MultiSelect';

const TABLE_HEADERS = [
  {
    label: 'Team Name',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'teamName',
    colSpan: 1,
  },
  {
    label: 'Manager Name',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'managerName',
    colSpan: 1,
  },
  {
    label: 'Hours worked today',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'hoursWorked',
    colSpan: 1,
  },
  {
    label: 'Idle time',
    colSpan: 1,
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'idleTime',
  },
  {
    label: 'Expected Hours',
    colSpan: 1,
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'expectedHours',
  },
  {
    label: 'Performance',
    colSpan: 0.5,
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'performance',
  },
  {
    label: 'Action',
    colSpan: 0.5,
  },
];

const CustomTableStyles = {
  container: {
    marginTop: 1,
  },
  header: {
    backgroundColor: 'var(--dark-10)',
    fontSize: '14px',
    '.MuiTableSortLabel-iconDirectionAsc': {
      transform: 'rotate(0deg) !important',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff !important',
    },
    '&:hover': {
      backgroundColor: 'var(--dark-10) !important',
    },
  },
  cell: {
    button: {
      textWrap: 'nowrap',
      color: 'var(--dark-2)',
      borderColor: 'var(--dark-4) !important',
      '&:disabled': {
        color: 'var(--dark-5)',
        borderColor: 'var(--dark-7) !important',
        cursor: 'not-allowed',
        pointerEvents: 'all !important',
      },
    },
  },
};

const Performance = [
  { id: 0, name: 'All' },
  { id: 1, name: 'Low' },
  { id: 2, name: 'Average' },
  { id: 3, name: 'High' },
];

function TeamPerformance() {
  const { search } = useLocation();
  const { activeWorkspace } = useContext(UserContext);
  const query = new URLSearchParams(search);
  const page = query.get('page');
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [performance, setPerformance] = useState([]);
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page, 10) : 1);
  const [teams, setTeams] = useState([]);
  const [managers, setManagers] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([{ id: 0, name: 'All' }]);
  const [selectedManagers, setSelectedManagers] = useState([{ id: 0, name: 'All' }]);
  const [selectedPerformance, setSelectedPerformance] = useState([{ id: 0, name: 'All' }]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}`);
  };

  const getTeamPerformance = async (params) => {
    setIsLoading(true);
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getTeamPerformance(activeWorkspace.id),
      params: {
        pageSize: 10,
        ...params,
      },
    });

    if (response.success) {
      const mappedPerformance = response.data?.performanceReports.map((report) => {
        const totalSecondsWorked = report?.totalSecondsWorked ?? 0;
        const totalActiveSeconds = report?.totalActiveSeconds ?? 0;
        const performanceRate = totalActiveSeconds === 0 ? 0
          : Math.round((totalActiveSeconds * 100) / totalSecondsWorked);
        // eslint-disable-next-line no-nested-ternary
        const performance = performanceRate < 21 ? 'Low' : performanceRate < 51 ? 'Average' : 'High';
        return {
          teamName: <p className="team-name-column">{report?.teamName}</p>,
          managerName: report?.managerName,
          hoursWorked: Math.round(totalSecondsWorked / 3600),
          idleTime:
            `${Math.round((totalSecondsWorked - totalActiveSeconds) / 3600)} Hours`,
          expectedHours: (report?.teamMemberCount ?? 0) * 8,
          performance: <span className="alert-badge" data-status={performance}>{performance}</span>,
          actions: (
            <Link to={`/members/team-engagement/${report?.teamId}`}>
              <Button
                type="button"
                className="inactive-status"
                variant="outlined"
              >
                View Team Engagement
              </Button>
            </Link>
          ),
        };
      });
      setPerformance(mappedPerformance);
      setIsLoading(false);
      setTotalPages(response.data?.totalPages ?? 1);
    } else {
      // handle API errors
    }
  };

  const getAllTeams = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getAllTeamInfo(activeWorkspace.id),
    });
    if (response.success) {
      const mappedTeams = response.data?.teams.map((team) => ({
        id: team?.id,
        name: team.name,
      }));
      const mappedManagers = response.data?.teams.map((team) => ({
        id: team.teamMates[0].member?.id,
        name: team.teamMates[0].member?.user?.fullName,
      }));

      const seenNames = {};
      const removedDuplicatedManagers = mappedManagers.filter((item) => {
        if (seenNames[item.name]) {
          return false;
        }
        seenNames[item.name] = true;
        return true;
      });
      setTeams([{ id: 0, name: 'All' }, ...mappedTeams]);
      setManagers([{ id: 0, name: 'All' }, ...removedDuplicatedManagers]);
    } else {
      // handle API errors
    }
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterTeamChange = (newValue) => {
    setSelectedTeams(newValue);
  };

  const handleFilterManagerChange = (newValue) => {
    setSelectedManagers(newValue);
  };

  const handleFilterPerformanceChange = (newValue) => {
    setSelectedPerformance(newValue);
  };

  const handleReset = () => {
    setSelectedTeams([{ id: 0, name: 'All' }]);
    setSelectedManagers([{ id: 0, name: 'All' }]);
    setSelectedPerformance([{ id: 0, name: 'All' }]);
  };

  const handleSearch = () => {
    const teamIds = selectedTeams.map((item) => item?.id);
    const performance = selectedPerformance.map((item) => item.name);
    const managers = selectedManagers.map((item) => item?.id);
    const params = {
      teamIds: teamIds[0] === 0 ? null : teamIds,
      performances: performance[0] === 'All' ? null : performance,
      managers: managers[0] === 0 ? null : managers,
      page: currentPage,
    };
    getTeamPerformance(params);
  };

  useEffect(() => {
    getTeamPerformance({ page: currentPage });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    getAllTeams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="py-2 px-3 d-flex justify-content-between align-items-center">
        <div>
          <h4 className="size-s text-600 lh-base fc-dark mb-1">
            Team Performance
          </h4>
          <p className="size-xs text-400 lh-sm fc-dark">
            Review every team’s performance along with their engagement details
          </p>
        </div>
        <button className="filter-btn" type="button" onClick={handleFilterClick}>Filter & Search</button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiMenu-list': {
              padding: 0,
            },
          }}
        >
          <div className="filter-panel p-3">
            <div className="filter-label mb-1">Employee Name</div>
            <MultiSelect options={teams} value={selectedTeams} handleChange={handleFilterTeamChange} />
            <div className="filter-label mb-1 mt-2">Manager Name</div>
            <MultiSelect
              options={managers}
              value={selectedManagers}
              handleChange={handleFilterManagerChange}
            />
            <div className="filter-label mb-1 mt-2">Performance</div>
            <MultiSelect
              options={Performance}
              value={selectedPerformance}
              handleChange={handleFilterPerformanceChange}
            />
            <div className="d-flex justify-content-end gap-3 mt-2">
              <button
                type="button"
                className="filter-reset-btn"
                onClick={() => handleReset()}
              >
                Reset
              </button>
              <button type="button" className="filter-search-btn" onClick={() => handleSearch()}>Search</button>
            </div>
          </div>
        </Menu>
      </div>
      <div>
        <CustomTable
          headers={TABLE_HEADERS}
          items={performance}
          loading={isLoading}
          pages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          styles={CustomTableStyles}
        />
      </div>
    </>
  );
}

export default TeamPerformance;
