import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function ProgressCircle({
  textColor,
  pathColor,
  trailColor,
  textSize,
  text,
  percentage,
}) {
  return (
    <div style={{ width: 100, height: 100 }}>
      <CircularProgressbar
        value={percentage}
        text={text}
        styles={buildStyles({
          textColor,
          pathColor,
          trailColor,
          textSize,
        })}
      />
    </div>
  );
}

ProgressCircle.propTypes = {
  textColor: PropTypes.string.isRequired,
  pathColor: PropTypes.string.isRequired,
  trailColor: PropTypes.string.isRequired,
  textSize: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
};

export default ProgressCircle;
