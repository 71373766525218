import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Box, Button, FormControl, TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

function SelectTimezoneBox({
  id, currentTimezone, saveTimezone, timezoneOptions,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(currentTimezone);

  useEffect(() => {
    setSelectedTimezone(currentTimezone); // Update the selected timezone when props change
  }, [currentTimezone]);

  const handleButtonClick = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div
      className="position-relative flex-row align-items-center"
    >
      <div
        className="timezone-container"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'nowrap', // Prevent wrapping
          gap: '8px', // Add spacing between text and button
        }}
      >
        <p
          className="timezone-text"
          style={{
            fontSize: '12px',
            margin: 0,
            whiteSpace: 'nowrap', // Prevent wrapping
            overflow: 'hidden',
            textOverflow: 'ellipsis', // Add ellipsis for overflow
          }}
        >
          {selectedTimezone || 'Workspace Default'}
        </p>
        <Button
          sx={{
            font: 'Inter',
            fontSize: '12px',
            lineHeight: '12px',
            fontWeight: '500',
            padding: '2px 8px',
            minWidth: 'fit-content',
          }}
          variant="outlined"
          color={isEditing ? 'error' : 'success'}
          onClick={handleButtonClick}
        >
          {isEditing ? 'Cancel' : 'Edit'}
        </Button>
      </div>
      {isEditing && (
        <Box
          width={312}
          sx={{
            padding: '16px',
            border: '1px solid #E0E1E6',
            position: 'absolute',
            top: '36px',
            backgroundColor: 'white',
            zIndex: '1',
            borderRadius: '4px',
          }}
        >
          <FormControl sx={{ width: '100%' }}>
            <Autocomplete
              value={selectedTimezone}
              onChange={(e, newValue) => setSelectedTimezone(newValue)}
              options={timezoneOptions}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...params}
                  label="Select Timezone"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
          <Button
            sx={{
              font: 'Inter',
              fontSize: '16px',
              lineHeight: '16px',
              fontWeight: '500',
              border: '1px solid',
              textTransform: 'none',
              borderRadius: '4px',
              backgroundColor: 'var(--green-3, #53A551)',
              color: '#FFFFFF',
              padding: '12px 24px',
              width: '100%',
              marginTop: '8px',
            }}
            onClick={() => {
              setIsEditing(false);
              saveTimezone({
                timezone: selectedTimezone,
                memberId: id,
              });
            }}
            variant="contained"
            disabled={!selectedTimezone}
          >
            Save
          </Button>
        </Box>
      )}
    </div>
  );
}

SelectTimezoneBox.propTypes = {
  id: PropTypes.number.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  saveTimezone: PropTypes.func.isRequired,
  timezoneOptions: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default SelectTimezoneBox;
