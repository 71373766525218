import React from 'react';
import { PropTypes } from 'prop-types';
import styled from '@emotion/styled';
import EmptyBox from './EmptyBox';
import { UserIcon } from '../../components/Icon';
import { convertSecToTimeFormat } from '../../utils/time';

const Table = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #E0E1E6;
  background: #FFF;
  box-shadow: 0px 7px 48px 0px rgba(0, 56, 153, 0.06);
  overflow: hidden;
  width: 100%;
`;

const TableHeader = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ECECF0;
  background: #FAFAFA;
`;

const TableHeaderTd = styled.span`
  color: #0E1E40;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 150px;
`;

const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TableBodyTr = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ECECF0;
`;

const TableBodyTd = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 150px;
  color: #293855;
  font-size: 14px;
  line-height: 20px;
`;

function EngagementCard({ members, alertsByMember }) {
  return (
    <Table>
      <TableHeader>
        <TableHeaderTd>Employee Name</TableHeaderTd>
        <TableHeaderTd>Work Hours</TableHeaderTd>
        <TableHeaderTd>Activity</TableHeaderTd>
        <TableHeaderTd>Quality Hours</TableHeaderTd>
        <TableHeaderTd>Bot Alerts</TableHeaderTd>
      </TableHeader>
      <TableBody>
        {members.length ? (
          members.map((member, key) => (
            <TableBodyTr key={key}>
              <TableBodyTd>
                <UserIcon color="#64BA62" width="24" height="24" />
                <span style={{ color: '#0E1E40', fontWeight: 500 }}>{member?.member?.user?.fullName}</span>
              </TableBodyTd>
              <TableBodyTd>{convertSecToTimeFormat(member?.member?.totalSecondsElapsed)}</TableBodyTd>
              <TableBodyTd>{`${member?.member?.engagementScore}%`}</TableBodyTd>
              <TableBodyTd>{convertSecToTimeFormat(member?.member?.totalActiveSeconds)}</TableBodyTd>
              <TableBodyTd>{alertsByMember?.[member?.member?.id]?.length || 0}</TableBodyTd>
            </TableBodyTr>
          ))
        ) : (
          <EmptyBox message="There are no engagement opportunities at this moment!" />
        )}
      </TableBody>
    </Table>
  );
}

EngagementCard.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({})),
  alertsByMember: PropTypes.shape({}),
};

export default EngagementCard;
