import React, { useState } from 'react';
import {
  FormControl, FormLabel, Box, Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import TimeSelector from '../../components/timeSelect';

function SelectTimeBox({
  id, timeStatus, startTime, endTime, saveTime,
}) {
  const [isEditing, setIsEditing] = useState(false);
  // Convert `HH:mm:ss` to `h:mm A` for display
  const convertedStartTime = startTime ? moment(startTime, 'HH:mm:ss').format('h:mm A') : '';
  const convertedEndTime = endTime ? moment(endTime, 'HH:mm:ss').format('h:mm A') : '';

  // Convert current times back to `HH:mm:ss` for saving
  const [currentStartTime, setCurrentStartTime] = useState(convertedStartTime);
  const [currentEndTime, setCurrentEndTime] = useState(convertedEndTime);

  const handleButtonClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = () => {
    setIsEditing(false);
    saveTime({
      timeStatus,
      startTime: moment(currentStartTime, 'h:mm A').format('HH:mm:ss'),
      endTime: moment(currentEndTime, 'h:mm A').format('HH:mm:ss'),
      memberId: id,
    });
    setIsEditing(!isEditing);
  };

  return (
    <div
      className="position-relative flex-row align-items-center"
    >
      <div
        className="time-container"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          gap: '8px',
        }}
      >
        <p
          className="time-text"
          style={{
            fontSize: '12px',
            margin: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {convertedStartTime && convertedEndTime
            ? `${convertedStartTime} - ${convertedEndTime}`
            : 'Workspace Default'}
        </p>
        <Button
          sx={{
            font: 'Inter',
            fontSize: '12px',
            lineHeight: '12px',
            fontWeight: '500',
            padding: '2px 8px',
            minWidth: 'fit-content', // Prevent button from taking extra space
          }}
          variant="outlined"
          color={isEditing ? 'error' : 'success'}
          onClick={handleButtonClick}
        >
          {isEditing ? 'Cancel' : 'Edit'}
        </Button>
      </div>
      {isEditing && (
        <Box
          width={312}
          height={182}
          sx={{
            padding: '16px',
            border: '1px solid #E0E1E6',
            position: 'absolute',
            top: '36px',
            backgroundColor: 'white',
            zIndex: '1',
            borderRadius: '4px',
          }}
        >
          <h5>{timeStatus === 'workingHours' ? 'Work Schedule' : 'Work Break Schedule'}</h5>
          <FormControl sx={{ width: '50%' }}>
            <FormLabel
              sx={{
                color: 'var(--dark-2)',
              }}
            >
              Starts from
            </FormLabel>
            <TimeSelector
              time={currentStartTime}
              setTime={setCurrentStartTime}
            />
          </FormControl>
          <FormControl sx={{ width: '50%' }}>
            <FormLabel
              sx={{
                color: 'var(--dark-2)',
                fontSize: '14px',
              }}
            >
              Ends at
            </FormLabel>
            <TimeSelector time={currentEndTime} setTime={setCurrentEndTime} />
          </FormControl>
          <Button
            sx={{
              font: 'Inter',
              fontSize: '16px',
              lineHeight: '16px',
              fontWeight: '500',
              border: '1px solid',
              textTransform: 'none',
              borderRadius: '4px',
              backgroundColor: 'var(--green-3, #53A551)',
              opacity: currentStartTime && currentEndTime ? '1' : '0.5',
              color: '#FFFFFF',
              padding: '12px 24px',
              width: '100%',
              marginTop: '8px',
            }}
            onClick={handleSave}
            variant="link"
            component="span"
            disabled={!(currentStartTime && currentEndTime)}
          >
            Save
          </Button>
        </Box>
      )}
    </div>
  );
}

SelectTimeBox.propTypes = {
  id: PropTypes.number.isRequired,
  timeStatus: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  saveTime: PropTypes.func.isRequired,
};

export default SelectTimeBox;
