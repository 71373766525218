import React, { useContext, useEffect, useCallback } from 'react';
import {
  Button,
  Box,
} from '@mui/material';
import CenterCardLayout from '../../components/CenterCardLayout';
import ShootLogo from '../../images/shootLogo.png';
import { getOperatingSystem } from '../../utils/system';
import { UserContext } from '../../contexts/User';
import { accountTypeLevel as ACCOUNT_TYPE_LEVEL } from '../../constants/accountTypes';

const OS = getOperatingSystem();
const version = process.env.REACT_APP_VERSION;

const downloadLinkEnvVariables = OS !== 'MacOS' ? {
  os: 'windows',
  app_name: process.env.REACT_APP_NODE_ENV === 'production'
    ? `Shoot+Setup+${version}.exe` : `Shoot+Staging+Setup+${version}.exe`,
} : {
  os: 'mac',
  app_name: process.env.REACT_APP_NODE_ENV !== 'production'
    ? `Shoot+Staging-${version}.dmg` : `Shoot-${version}.dmg`,
};

const downloadLink = `${process.env.REACT_APP_DOWNLOAD_LINK}`
  .replace('{{ENV}}', process.env.REACT_APP_NODE_ENV)
  .replace('{{OS}}', downloadLinkEnvVariables.os)
  .replace('{{APP_NAME}}', downloadLinkEnvVariables.app_name);
function Download() {
  const { member, redirect } = useContext(UserContext);
  const headerImage = <img src={ShootLogo} alt="shoot-logo" height={64} width={64} style={{ marginBottom: 24 }} />;

  const handleRedirect = useCallback(() => {
    setTimeout(() => {
      if (ACCOUNT_TYPE_LEVEL[member.accountType] >= ACCOUNT_TYPE_LEVEL['Workspace admin']) {
        redirect('/dashboard');
      } else {
        redirect('/pto/requests');
      }
    }, 1000);
  }, [member.accountType, redirect]);

  useEffect(() => {
    if (sessionStorage.getItem('download')) {
      handleRedirect();
    }
  }, [handleRedirect]);

  const handleClick = () => {
    sessionStorage.setItem('download', true);
    handleRedirect();
  };

  return (
    <CenterCardLayout
      title="Welcome to Shoot"
      description="To begin tracking time you’ll need to download and install our desktop app"
      testId="login-form"
      headerImage={headerImage}
    >
      <Box sx={{ width: (2 / 3), margin: '0 auto' }}>
        <div className="large-bottom-margin">
          <Button
            fullWidth
            variant="contained"
            sx={{
              fontSize: 16,
              fontWeight: 600,
              textTransform: 'unset',
              '&: hover': {
                color: '#ffffff',
              },
              padding: '14px',
            }}
            href={downloadLink}
            download
            onClick={handleClick}
          >
            Download Desktop App
          </Button>
        </div>
      </Box>
    </CenterCardLayout>
  );
}

export default Download;
