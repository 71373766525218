import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  IconButton,
} from '@mui/material';
import {
  AlertIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  UserIcon,
} from '../../components/Icon';

function BotAlertCard({ title, color, alerts }) {
  const totalCount = alerts.length;
  const [number, setNumber] = useState(alerts.length ? 1 : 0);

  useEffect(() => {
    if (totalCount) {
      setNumber(1);
    }
  }, [totalCount]);

  return (
    <Card
      variant="outlined"
      sx={{
        flex: 1,
        borderRadius: '8px',
        border: '1px solid #E0E1E6',
        boxShadow: '0px 7px 48px 0px rgba(0, 56, 153, 0.06)',
      }}
    >
      <CardContent
        sx={{
          padding: '0 !important',
          height: '100%',
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        {/* Header Section */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            background: '#FBFBFC',
            padding: '10px 16px',
            borderBottom: '1px solid #E0E1E6',
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center" sx={{ color: '#CC4525' }}>
            <AlertIcon width="20" height="20" />
            <Typography
              sx={{
                fontWeight: '600!important',
                color: '#CC4525',
                textTransform: 'capitalize',
              }}
            >
              {title}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={0.5}>
            <IconButton
              size="small"
              disabled={number <= 1}
              onClick={() => { setNumber(number - 1); }}
              sx={{
                padding: 0,
                '&.Mui-disabled': {
                  opacity: 0.6,
                },
              }}
            >
              <ArrowLeftIcon />
            </IconButton>
            <Typography
              sx={{
                color: '#7D8698',
                fontSize: '12px',
                fontWeight: '500 !important',
              }}
            >
              {number}
              /
              {totalCount}
            </Typography>
            <IconButton
              size="small"
              disabled={number >= totalCount}
              onClick={() => { setNumber(number + 1); }}
              sx={{
                padding: 0,
                '&.Mui-disabled': {
                  opacity: 0.6,
                },
              }}
            >
              <ArrowRightIcon />
            </IconButton>
          </Stack>
        </Stack>

        { alerts.length ? (
          <Stack direction="column" sx={{ flex: 1 }}>
            <Stack direction="column" sx={{ padding: '8px 16px', flex: 1 }}>
              <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
                <UserIcon width="20" height="20" color={color} />
                <Typography
                  color="#4469A1"
                  sx={{ fontWeight: '600 !important' }}
                >
                  {alerts[number - 1]?.userFullName}
                </Typography>
              </Stack>

              <Typography color="#293855" sx={{ lineHeight: '20px!important' }}>
                {alerts[number - 1]?.alertMessageInternal}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                padding: '8px 12px',
                background: '#FBFBFC',
                borderTop: '1px solid #E0E1E6',
              }}
            >
              {/* <Typography color="#546077" lineHeight="20px!important"> */}
              {/*  Team: */}
              {/*  {' '} */}
              {/*  <span style={{ color: '#0E1E40', fontWeight: 500 }}>{alerts[number - 1].team}</span> */}
              {/* </Typography> */}
              <span style={{ width: '1px', background: '#E0E1E6', height: '20px' }} />
              <Typography color="#546077" lineHeight="20px!important">
                Manager:
                {' '}
                <span style={{ color: '#0E1E40', fontWeight: 500 }}>
                  {alerts[number - 1]?.member?.Manager?.user.firstName}
                </span>
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack direction="column" sx={{ flex: 1 }}>
            <Stack direction="column" sx={{ padding: '8px 16px' }}>
              <Typography
                color="#293855"
                className="py-1"
                sx={{ flex: 1, lineHeight: '20px!important' }}
              >
                {`There are no ${title} at this moment!`}
              </Typography>
            </Stack>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}

BotAlertCard.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  alerts: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string.isRequired,
    memberId: PropTypes.number.isRequired,
    message: PropTypes.string,
    userFullName: PropTypes.string.isRequired,
    alertMessageInternal: PropTypes.string.isRequired,
    member: PropTypes.shape({
      Manager: PropTypes.shape({
        user: PropTypes.shape({
          firstName: PropTypes.string,
        }),
      }),
    }),
    // eslint-disable-next-line react/forbid-prop-types
    alertType: PropTypes.object,
  })).isRequired,
};

export default BotAlertCard;
