import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Modal, Box, Button } from '@mui/material';
import ResolveIcon from '../../images/resolve.png';

const Description = styled.p`
    font-size: ${(props) => `${props.fontSize}px`};
    color: ${(props) => props.color};
    font-weight: ${(props) => props.fontWeight};
    margin-top: ${(props) => `${props.marginTop}px`};
`;

function ResolveModal({
  closeModal,
  handleResolve,
  isAll,
}) {
  const handleClick = () => {
    handleResolve();
    closeModal();
  };

  return (
    <Modal
      open
      onClose={closeModal}
      className="flex-row align-c jstfy-c create-team-modal"
      data-testid="resolve-modal"
    >
      <Box style={{
        width: 541,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <img src={ResolveIcon} width="48" height="48" alt="trash" data-testid="trash-icon" />
        <div className="modal-body" style={{ padding: 0 }}>
          {isAll ? (
            <Description
              fontSize={20}
              fontWeight={600}
              color="#0E1E40"
              marginTop={16}
              data-testid="modal-title"
            >
              Are you sure you want to resolve all issues?
            </Description>
          ) : (
            <Description
              fontSize={20}
              fontWeight={600}
              color="#0E1E40"
              marginTop={16}
              data-testid="modal-title"
            >
              Are you sure you want to resolve this issue?
            </Description>
          )}
          <Description
            fontSize={14}
            fontWeight={400}
            color="#293855"
            marginTop={4}
            data-testid="modal-description"
          >
            {/* eslint-disable-next-line max-len */}
            {isAll && 'This action cannot be undone and will mark all issues as resolved. Please confirm your decision below.'}
            {!isAll && 'This action cannot be undone. Please confirm your decision below.'}
          </Description>
        </div>

        <div className="modal-footer-customized">
          <Button
            sx={{
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
            }}
            variant="outlined"
            onClick={closeModal}
            color="secondary"
            data-testid="cancel"
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '132px',
              backgroundColor: '#53A551',
              '&:hover': { backgroundColor: '#53A551' },
            }}
            variant="contained"
            onClick={() => handleClick()}
            data-testid="resolve-violations"
          >
            {isAll ? 'Resolve All' : 'Resolve'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

ResolveModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleResolve: PropTypes.func.isRequired,
  isAll: PropTypes.bool.isRequired,
};

export default ResolveModal;
