import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import EmptyBoxImg from '../../images/empty-box.png';

const EmptyBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  gap: 8px;
`;

const EmptyBoxInfo = styled.p`
  color: #546077;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
`;

function EmptyBox({ message }) {
  return (
    <EmptyBoxWrapper>
      <img src={EmptyBoxImg} width="100" height="100" alt="Empty" />
      <EmptyBoxInfo>{message}</EmptyBoxInfo>
    </EmptyBoxWrapper>
  );
}

EmptyBox.propTypes = {
  message: PropTypes.string.isRequired,
};

export default EmptyBox;
