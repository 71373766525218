import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import {
  Autocomplete, Box, Button, TextField,
} from '@mui/material';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import { NotificationContext } from '../../contexts/Notification';
import useDebounce from '../../hooks/useDebounce';
import CustomTable from '../../components/table/CustomTable';
import MemberNameEmailColumn from '../../components/table/MemberNameEmailColumn';
import Toggle from '../../components/Toggle';
import Pagination from '../../components/pagination';
import TimeSelector from '../../components/timeSelect';
import SelectTimeBox from './SelectTimeBox';
import { isTimeRangeWithin } from '../../utils/time';
import SelectTimezoneBox from './SelectTimezoneBox';

const TABLE_HEADERS = [
  {
    label: 'User',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'name',
    colSpan: 1,
  },
  {
    label: 'Working Hours',
    sortKey: 'activitySeconds',
    colSpan: 0.5,
  },
  {
    label: 'Schedule',
    colSpan: 1,
  },
  // {
  //   label: 'Work Break',
  //   colSpan: 1,
  // },
  {
    label: 'Timezone',
    colSpan: 1,
  },
  {
    label: 'Action',
    colSpan: 0.5,
  },
];

const CustomTableStyles = {
  container: {
    marginTop: 1,
  },
  header: {
    backgroundColor: 'var(--dark-10)',
    fontSize: '16px',
    '.MuiTableSortLabel-iconDirectionAsc': {
      transform: 'rotate(0deg) !important',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff !important',
    },
    '&:hover': {
      backgroundColor: 'var(--dark-10) !important',
    },
  },
  cell: {
    button: {
      textWrap: 'nowrap',
    },
  },
};

function WorkingHours() {
  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  const page = query.get('page');
  const { activeWorkspace, user } = useContext(UserContext);
  const { displayNotification } = useContext(NotificationContext);
  const [rawData, setRawData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortParams, setSortParams] = useState({ sortKey: '', sortOrder: 'asc' });
  const { debounce } = useDebounce();
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page, 10) : 1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCounts, setTotalCounts] = useState(0);
  const [workingHoursPreference, setWorkingHoursPreference] = useState([]);
  // const [orgWorkingDayTime, setOrgWorkingDayTime] = useState({ startTime: '', endTime: '' });
  // const [orgWorkBreakTime, setOrgWorkBreakTime] = useState({ startTime: '', endTime: '' });
  const [orgTimezone, setOrgTimezone] = useState('Etc/UTC');
  const [selectedTimezone, setSelectedTimezone] = useState(user.timezone);
  // const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [workspaceDefault, setWorkspaceDefault] = useState({
    workingDayStartTime: '',
    workingDayEndTime: '',
    workBreakStartTime: '',
    workBreakEndTime: '',
    timezone: '',
  });
  const [isDirty, setIsDirty] = useState(false);

  const allTimezones = moment.tz.names();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(debounce((query) => setSearchQuery(query), 500), []);

  const convertTimeFormatAndTimezone = useCallback((timeString, timezone, defaultTime) => (
    timeString
      ? moment.tz(timeString, 'HH:mm:ss', timezone)
        .tz(selectedTimezone)
        .format('h:mm A')
      : convertTimeFormatAndTimezone(defaultTime, orgTimezone)
  ), [selectedTimezone, orgTimezone]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}`);
  };

  const handlePageSizeChange = (pageSize) => {
    setCurrentPage(1);
    setTotalPages(Math.ceil(totalCounts / pageSize));
    setPageSize(pageSize);
  };

  const changeWorkingHours = (type, memberId) => {
    setWorkingHoursPreference({ ...workingHoursPreference, [memberId]: type });
    if (type === 'Default') {
      const tmpData = { ...rawData };
      const selectedMember = tmpData?.members?.find(({ id }) => id === memberId);
      selectedMember.workingDayStartTime = null;
      selectedMember.workingDayEndTime = null;
      selectedMember.workBreakStartTime = null;
      selectedMember.workBreakEndTime = null;
      setRawData(tmpData);

      sendRequest({
        method: 'put',
        url: API.WORKSPACE.updateWorkingHours(activeWorkspace.id, memberId),
      });
    }
  };

  const handleUpdateWorkspaceDefault = (key, timeString) => {
    setWorkspaceDefault({ ...workspaceDefault, [key]: timeString });
    setIsDirty(true);
  };

  const submitWorkspaceWorkingSchedule = async () => {
    await sendRequest({
      method: 'put',
      url: API.WORKSPACE.updateWorkspaceWorkingHours(activeWorkspace.id),
      data: {
        workingDayStartTime: moment(
          workspaceDefault.workingDayStartTime,
          'h:mm A',
        ).format('HH:mm:ss'),
        workingDayEndTime: moment(workspaceDefault.workingDayEndTime, 'h:mm A').format('HH:mm:ss'),
        timezone: workspaceDefault.timezone,
      },
    });

    setIsDirty(false);

    displayNotification({
      message: 'Workspace working hours updated successfully',
      severity: 'success',
    });
  };

  const setMemberWorkingDayTime = async ({
    timeStatus, startTime, endTime, memberId,
  }) => {
    const tmpData = { ...rawData };
    const selectedMember = tmpData?.members?.find(({ id }) => id === memberId);

    const formatTime = (time) => moment
      .tz(time, 'h:mm A', 'Etc/UTC')
      .utc()
      .format('HH:mm:ss');

    const utcStartTime = formatTime(startTime);
    const utcEndTime = formatTime(endTime);

    // Work Break Time validation
    const {
      workingDayStartTime, workingDayEndTime, workBreakStartTime, workBreakEndTime,
    } = selectedMember;
    if (workingDayStartTime && workingDayEndTime && timeStatus === 'workBreak') {
      if (!isTimeRangeWithin(workingDayStartTime, workingDayEndTime, utcStartTime, utcEndTime)) {
        displayNotification({
          message: 'Work breaks should be included in the working hours.',
          severity: 'error',
        });
        return;
      }
    } else if (workBreakStartTime && workBreakEndTime && timeStatus === 'workingHours') {
      if (!isTimeRangeWithin(utcStartTime, utcEndTime, workBreakStartTime, workBreakEndTime)) {
        selectedMember.workBreakStartTime = null;
        selectedMember.workBreakEndTime = null;
      }
    }

    const timeFields = {
      workingHours: {
        start: 'workingDayStartTime',
        end: 'workingDayEndTime',
      },
      workBreak: {
        start: 'workBreakStartTime',
        end: 'workBreakEndTime',
      },
    };
    const fields = timeFields[timeStatus];

    selectedMember[fields.start] = utcStartTime;
    selectedMember[fields.end] = utcEndTime;
    setRawData(tmpData);

    await sendRequest({
      method: 'put',
      url: API.WORKSPACE.updateWorkingHours(activeWorkspace.id, memberId),
      params: {
        [fields.start]: utcStartTime,
        [fields.end]: utcEndTime,
      },
    });

    displayNotification({
      message: 'Member working hours updated successfully',
      severity: 'success',
    });
  };

  const setMemberTimezone = ({ timezone, memberId }) => {
    setSelectedTimezone(timezone);
    sendRequest({
      method: 'put',
      url: API.WORKSPACE.updateWorkingHours(activeWorkspace.id, memberId),
      params: {
        workingScheduleTimezone: timezone,
      },
    });
  };

  useEffect(() => {
    setIsLoading(true);
    sendRequest({
      method: 'GET',
      url: API.WORKSPACE.membersWorkingHours(activeWorkspace.id),
      params: {
        name: searchQuery,
        page: currentPage,
        pageSize,
        ...sortParams,
      },
    }).then(({ data, success }) => {
      if (success) {
        const initialPreferences = data?.members.reduce((acc, { id, workingDayStartTime, workingDayEndTime }) => {
          acc[id] = workingDayStartTime || workingDayEndTime ? 'Custom' : 'Default';
          return acc;
        }, {});
        setWorkingHoursPreference(initialPreferences);
        setRawData(data);
        setTotalPages(data?.pageInfo.totalPages ?? 1);
        setTotalCounts(data?.pageInfo.totalCounts ?? 0);
        setOrgTimezone(data?.organization.timezone ?? 'Etc/UTC');
        setWorkspaceDefault({
          ...data.organization,
          workingDayStartTime: moment(data.organization.workingDayStartTime, 'HH:mm:ss').format('h:mm A'),
          workingDayEndTime: moment(data.organization.workingDayEndTime, 'HH:mm:ss').format('h:mm A'),
          workBreakStartTime: moment(data.organization.workBreakStartTime, 'HH:mm:ss').format('h:mm A'),
          workBreakEndTime: moment(data.organization.workBreakEndTime, 'HH:mm:ss').format('h:mm A'),
        });
      }
    }).finally(() => setIsLoading(false));
  }, [searchQuery, sortParams, currentPage, pageSize]);

  const members = useMemo(
    () => rawData?.members?.map(
      ({
        id,
        user,
        haveJoined,
        workingDayStartTime,
        workingDayEndTime,
        // workBreakStartTime,
        // workBreakEndTime,
        workingScheduleTimezone,
      }) => ({
        name: (
          <MemberNameEmailColumn
            profilePicture={user?.profilePicture}
            firstName={haveJoined ? user?.firstName : ''}
            lastName={haveJoined ? user?.lastName : ''}
            email={user?.email}
            component="div"
          />
        ),
        WorkingHours: (
          <Toggle
            activeLabel={workingHoursPreference[id]}
            options={[
              {
                label: 'Default',
                onClick: () => changeWorkingHours('Default', id),
              },
              {
                label: 'Custom',
                onClick: () => changeWorkingHours('Custom', id),
              },
            ]}
            delay={0}
          />
        ),
        Schedule:
            workingHoursPreference[id] === 'Custom' ? (
              <SelectTimeBox
                id={id}
                timeStatus="workingHours"
                startTime={workingDayStartTime}
                endTime={workingDayEndTime}
                saveTime={setMemberWorkingDayTime}
              />
            ) : (
              <p style={{ color: 'var(--dark-4)', fontSize: '12px' }}>Workspace Default</p>
            ),
        // WorkBreak:
        //     workingHoursPreference[id] === 'Custom' ? (
        //       <SelectTimeBox
        //         id={id}
        //         timeStatus="workBreak"
        //         startTime={workBreakStartTime}
        //         endTime={workBreakEndTime}
        //         saveTime={setMemberWorkingDayTime}
        //       />
        //     ) : (
        //       <p style={{ color: 'var(--dark-4)', fontSize: '12px' }}>Workspace Default</p>
        //     ),
        Timezone:
            workingHoursPreference[id] === 'Custom' ? (
              <SelectTimezoneBox
                id={id}
                timeStatus="timezone"
                currentTimezone={workingScheduleTimezone}
                saveTimezone={setMemberTimezone}
                timezoneOptions={allTimezones}
              />
            ) : (
              <p style={{ color: 'var(--dark-4)', fontSize: '12px' }}>Workspace Default</p>
            ),
        Action: (
          <Button
            variant="outlined"
            sx={{
              color: 'var(--dark-2)',
              borderColor: 'var(--dark-4)',
            }}
          >
            View Time Worked
          </Button>
        ),
      }),
    ),
    [rawData, workingHoursPreference, selectedTimezone],
  );

  const handleSort = debounce((sortKey, sortOrder) => {
    setSortParams({ sortKey, sortOrder });
  }, 250);

  return (
    <div>
      <div className="texts-section px-3 py-2">
        <p className="size-s text-600 lh-base fc-dark mb-1">
          Working Hours
        </p>
        <p className="size-xs text-400 lh-sm fc-dark mb-3">
          Specify the working hours for the team members in your organization.
        </p>
        <p className="size-xs text-600 mb-1">Workspace Default</p>
        <div className="flex-row align-items-center gap-2 mb-2">
          <p>Work From</p>
          <TimeSelector
            time={workspaceDefault.workingDayStartTime}
            setTime={(timeString) => handleUpdateWorkspaceDefault('workingDayStartTime', timeString)}
          />
          <p>To</p>
          <TimeSelector
            time={workspaceDefault.workingDayEndTime}
            setTime={(timeString) => handleUpdateWorkspaceDefault('workingDayEndTime', timeString)}
          />
          <p>Timezone</p>
          <Box
            sx={{
              width: '100%', // Full width by default
              maxWidth: '300px', // Limit the maximum width to 400px
            }}
          >
            <Autocomplete
              value={workspaceDefault.timezone}
              onChange={(e, newValue) => handleUpdateWorkspaceDefault('timezone', newValue)}
              options={allTimezones}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...params}
                  fullWidth
                  // label="Timezone"
                  variant="outlined"
                />
              )}
              sx={{
                width: '100%', // Ensure the autocomplete takes full width of its container
              }}
            />
          </Box>
          {isDirty && <Button onClick={submitWorkspaceWorkingSchedule}>Save Changes</Button>}
        </div>
        <div className="flex-row align-items-center gap-2 mb-2">
          {/* <p>Work Break From</p> */}
          {/* <TimeSelector */}
          {/*  time={workspaceDefault.workBreakStartTime} */}
          {/*  setTime={(timeString) => handleUpdateWorkspaceDefault('workBreakStartTime', timeString)} */}
          {/* /> */}
          {/* <p>To</p> */}
          {/* <TimeSelector */}
          {/*  time={workspaceDefault.workBreakEndTime} */}
          {/*  setTime={(timeString) => handleUpdateWorkspaceDefault('workBreakEndTime', timeString)} */}
          {/* /> */}
        </div>
        <div className="in-block">
          <p className="text-600 mb-1">Individual Settings</p>
          <p className="size-xs">Override the organization default for specific members</p>
        </div>
        <div className="searchBox">
          <input type="text" placeholder="Search for user" onChange={(e) => handleSearch(e.target.value)} />
          <i className="fa fa-search searchIcon" />
        </div>
      </div>

      <CustomTable
        headers={TABLE_HEADERS}
        items={members}
        loading={isLoading}
        handleSort={handleSort}
        styles={CustomTableStyles}
      />
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        totalPages={totalPages}
        totalCounts={totalCounts}
        onPageChange={handlePageChange}
        setPageSize={handlePageSizeChange}
        styles={{ margin: 0 }}
      />
    </div>
  );
}

export default WorkingHours;
