import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Button, Box, Grid, MenuItem, FormControl, TextField, Autocomplete,
} from '@mui/material';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import PlacesAutocomplete from '../../components/PlacesAutocomplete';

function EditMemberModal({
  member, closeModal, getMembers, reportingManagers,
}) {
  const { activeWorkspace } = useContext(UserContext);
  const [formData, setFormData] = useState({
    firstName: member.user.firstName,
    lastName: member.user.lastName,
    email: member.user.email,
    timezone: member.user.timezone,
    accountType: member.accountType,
    department: member.department,
    designation: member.designation,
    latlng: {
      latitude: member.user.userLocation?.latitude,
      longitude: member.user.userLocation?.longitude,
    },
    location: {
      description: member.user.userLocation?.formattedAddress,
    },
    reportingManager: member.reportingManager,
  });
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [isUpdating, setIsUpdating] = useState(false);

  const isUpdatable = () => formData.accountType !== member.accountType
            || formData.department !== member.department
            || formData.designation !== member.designation
            || formData.latlng?.latitude !== member.user.userLocation?.latitude
            || formData.latlng?.longitude !== member.user.userLocation?.longitude
            || formData.location?.description !== member.user.userLocation?.formattedAddress
            || formData.reportingManager !== member.reportingManager;

  const handleForm = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEditMember = async () => {
    setIsUpdating(true);
    const response = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.editMember(activeWorkspace.id, member.id),
      data: formData,
    });
    setIsUpdating(false);
    if (response.success) {
      setSuccessMessage('Updated member successfully.');
      setErrorMessage('');
      getMembers();
      closeModal();
    } else {
      setErrorMessage('Something went wrong. Please try again.');
    }
  };

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c" data-testid="edit-member-modal">
      <Box style={{
        width: 482,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <h5 className="m-b16 text-600" data-testid="modal-title">Edit Member</h5>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                name="firstName"
                label="First Name"
                variant="standard"
                value={formData?.firstName}
                type="text"
                disabled
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                name="lastName"
                label="Last Name"
                variant="standard"
                value={formData?.lastName}
                type="text"
                disabled
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                name="email"
                label="Email Address"
                variant="standard"
                value={formData?.email}
                type="email"
                disabled
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                select
                name="accountType"
                value={formData.accountType}
                label="Account Type"
                onChange={handleForm}
                disabled={formData.accountType === 'Primary owner'}
                variant="standard"
              >
                <MenuItem
                  value="Primary owner"
                  hidden={formData.accountType !== 'Primary owner'}
                >
                  Primary owner
                </MenuItem>
                <MenuItem value="Team member">Team member</MenuItem>
                <MenuItem value="Team Leader">Team Leader</MenuItem>
                <MenuItem value="Workspace owner">Workspace owner</MenuItem>
                <MenuItem value="Workspace admin">Workspace admin</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12}> */}
          {/*  <FormControl fullWidth> */}
          {/*    <TextField */}
          {/*      name="timezone" */}
          {/*      label="Timezone" */}
          {/*      variant="standard" */}
          {/*      value={formData?.timezone} */}
          {/*      type="text" */}
          {/*      disabled */}
          {/*    /> */}
          {/*  </FormControl> */}
          {/* </Grid> */}
          <Grid item xs={12}>
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                value={
                    reportingManagers.find((m) => m.id === formData.reportingManager) || null
                  }
                onChange={(e, newValue) => {
                  setFormData({ ...formData, reportingManager: newValue?.id || '' });
                }}
                options={reportingManagers.filter((m) => m.id !== member.id)}
                getOptionLabel={(option) => (`${option.user.firstName} ${option.user.lastName}`) || ''}
                renderInput={(params) => (
                  <TextField
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...params}
                    label="Reporting To"
                    variant="standard"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                select
                name="department"
                value={formData.department}
                label="Department"
                onChange={handleForm}
                variant="standard"
                data-testid="department"
              >
                <MenuItem value="Others">Others</MenuItem>
                <MenuItem value="Contractors">Contractors</MenuItem>
                <MenuItem value="Product Development">Product Development</MenuItem>
                <MenuItem value="Development">Development</MenuItem>
                <MenuItem value="BPO">BPO</MenuItem>
                <MenuItem value="Izenda">Izenda</MenuItem>
                <MenuItem value="ChartRequest">ChartRequest</MenuItem>
                <MenuItem value="Full Cycle">Full Cycle</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                select
                name="designation"
                value={formData.designation}
                label="Designation"
                onChange={handleForm}
                variant="standard"
                data-testid="designation"
              >
                <MenuItem value="Others">Others</MenuItem>
                <MenuItem value="Software Developer">Software Developer</MenuItem>
                <MenuItem value="Senior Data Scientist">Senior Data Scientist</MenuItem>
                <MenuItem value="Team Lead">Team Lead</MenuItem>
                <MenuItem value="Business Process Analyst">Business Process Analyst</MenuItem>
                <MenuItem value="BI Analyst">BI Analyst</MenuItem>
                <MenuItem value="Customer Success Associate">Customer Success Associate</MenuItem>
                <MenuItem value="Software Developer II">Software Developer II</MenuItem>
                <MenuItem value="QA Lead">QA Lead</MenuItem>
                <MenuItem value="Business Analyst">Business Analyst</MenuItem>
                <MenuItem value="Junior Software Developer">Junior Software Developer</MenuItem>
                <MenuItem value="Senior Business Analyst">Senior Business Analyst</MenuItem>
                <MenuItem value="QA Manager">QA Manager</MenuItem>
                <MenuItem value="HR">HR</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <PlacesAutocomplete
              location={formData.location}
              onChange={setFormData}
              textfieldType="standard"
              label="Home Address"
              placeholder="Enter Street Address"
            />
          </Grid>
        </Grid>
        <div className="newMemberForm-btns" style={{ marginTop: 24 }}>
          <Button
            onClick={closeModal}
            color="secondary"
            sx={{ marginRight: 2 }}
            data-testid="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleEditMember}
            disabled={!isUpdatable() || isUpdating}
            data-testid="update-member"
          >
            {isUpdating ? 'Updating...' : 'Update'}
          </Button>
        </div>
        <div className="success-msg">{successMessage}</div>
        <div className="error-msg">{errorMessage}</div>
      </Box>
    </Modal>
  );
}

EditMemberModal.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    accountType: PropTypes.string,
    department: PropTypes.string,
    designation: PropTypes.string,
    reportingManager: PropTypes.number,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      timezone: PropTypes.string,
      userLocation: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        formattedAddress: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  getMembers: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  reportingManagers: PropTypes.array.isRequired,
};

export default EditMemberModal;
