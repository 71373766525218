import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ClockIcon, TimerClockIcon, TimerLineIcon } from '../../components/Icon';
import { convertSecToTimeFormat } from '../../utils/time';

const ActivityWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ActivityCard = styled.div`
  display: flex;
  padding: 5px 16px;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  border-radius: 4px;
  border: 1px solid #E0E1E6;
  background: #FFF;
  min-height: 40px;
`;

const ActivityTitleWrapper = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
`;

const ActivityTitle = styled.p`
  text-transform: uppercase;
  display: flex;
  font-size: 14px;
  color: #0E1E40;
  font-weight: 500;
  line-height: 20px;
`;

const ActivityValue = styled.div`
  color: #0E1E40;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

const ScoreBadge = styled.span`
  display: flex;
  padding: 4px 8px;
  border-radius: 4px;
  background: #53A551;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

function ActivityStatus({ totalSeconds, activeSeconds }) {
  return (
    <ActivityWrapper className="mb-3">
      <ActivityCard>
        <ActivityTitleWrapper>
          <ClockIcon width="24" height="24" color="#5A81BC" />
          <ActivityTitle>Total Hours</ActivityTitle>
        </ActivityTitleWrapper>
        <ActivityValue>{ totalSeconds ? convertSecToTimeFormat(totalSeconds) : '-' }</ActivityValue>
      </ActivityCard>
      <ActivityCard>
        <ActivityTitleWrapper>
          <TimerClockIcon width="24" height="24" color="#5A81BC" />
          <ActivityTitle>Quality Hours</ActivityTitle>
        </ActivityTitleWrapper>
        <ActivityValue>{ activeSeconds ? convertSecToTimeFormat(activeSeconds) : '-' }</ActivityValue>
      </ActivityCard>
      <ActivityCard>
        <ActivityTitleWrapper>
          <TimerLineIcon width="24" height="24" color="#5A81BC" />
          <ActivityTitle>Engagement Score</ActivityTitle>
        </ActivityTitleWrapper>
        { activeSeconds ? (
          <ScoreBadge>{`${Math.round((activeSeconds * 100) / totalSeconds)}%`}</ScoreBadge>
        ) : (
          <ActivityValue>-</ActivityValue>
        ) }
      </ActivityCard>
    </ActivityWrapper>
  );
}

ActivityStatus.propTypes = {
  totalSeconds: PropTypes.number.isRequired,
  activeSeconds: PropTypes.number.isRequired,
};

export default ActivityStatus;
