import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../../contexts/User';
import useWorkspaces from '../../hooks/useWorkspaces';
import WorkspaceSVG from '../../images/workspace.svg';
import { Dropdown } from '../muiCustomized/Dropdown';
import './sidebar.css';
import SubMenu from './submenu';
import { accountTypeLevel } from '../../constants/accountTypes';

const Avatar = styled.div`
  border-radius: 6px;
  width: 39px;
  height: 39px;
  background: var(--dark-7);
  color: var(--dark-3);
  ${(props) => props.src && `
    background-size: cover;
    background-image: url(${props.src});
  `}
`;

const linkArrayNamesAdmin = [
  {
    routeName: '/dashboard',
    text: 'Dashboard',
    enabled: true,
  },
  {
    text: 'Team Engagement',
    routeName: '/members/team-engagement',
    enabled: true,
  },
  {
    text: 'Reports',
    enabled: true,
    submenuLocation: '/reports',
    accountLevel: 2,
    subRoutes: [
      { text: 'Time Worked', routeName: '/reports/time-worked', enabled: true },
      { text: 'Webcam Audit Results', routeName: '/reports/webcam-results', enabled: true },
      {
        routeName: '/insiderThreats',
        text: 'Insider Threats',
        enabled: true,
      },
      { text: 'Timesheets', routeName: '/members/timesheets', enabled: true },
      { text: 'Geolocation Results', routeName: '/reports/geo-results', enabled: true },
      { text: "Team Member's Time Off", routeName: '/pto/members', enabled: true },
    ],
  },
  {
    routeName: '/geoDashboard',
    text: 'Geo Dashboard',
    enabled: true,
  },
  {
    text: 'My Shoot',
    submenuLocation: '/pto',
    subRoutes: [
      { text: 'Personal Time Off (PTO)', routeName: '/pto/requests', enabled: true },
    ],
    enabled: true,
  },
  { text: 'Manage Members', routeName: '/members/manage', enabled: true },
  // {
  //   text: 'Manage Members',
  //   // Used within the submenu component to look at the user's path and keep the menu open
  //   submenuLocation: '/members',
  //   accountLevel: 2,
  //   subRoutes: [
  //     { text: 'Members', routeName: '/members/manage', enabled: true },
  //     // { text: 'Screenshots', routeName: '/members/screenshotFrequency', enabled: true },
  //     // { text: 'Webcam Audit', routeName: '/members/webcamAudit', enabled: true },
  //     // { text: 'Activity', routeName: '/members/activity', enabled: true },
  //     // { text: 'Data Retention', routeName: '/members/dataRetention' },
  //     // { text: 'Messaging', routeName: '/members/messaging' },
  //   ],
  //   enabled: true,
  // },
  {
    text: 'Settings & Permissions',
    // Used within the submenu component to look at the user's path and keep the menu open
    submenuLocation: '/settings',
    accountLevel: 2,
    subRoutes: [
      { text: 'Screenshots', routeName: '/members/screenshotFrequency', enabled: true },
      { text: 'Webcam Audit', routeName: '/members/webcamAudit', enabled: true },
      { text: 'All Settings', routeName: '/admin/settings', enabled: true },
      // { text: 'Activity', routeName: '/members/activity', enabled: true },
      // { text: 'Data Retention', routeName: '/members/dataRetention' },
      // { text: 'Messaging', routeName: '/members/messaging' },
    ],
    enabled: true,
  },
  // {
  //   routeName: '/devices',
  //   text: 'Devices Dashboard',
  // },
  // {
  //   routeName: '/complianceDashboard',
  //   text: 'Compliance Dashboard',
  // },
  // {
  //   routeName: '/itAssistanceRequests',
  //   text: 'IT Assistance Requests',
  // },
  // {
  //   routeName: '/widgets',
  //   text: 'Widgets',
  // },
  // {
  //   routeName: '/analytics',
  //   text: 'Analytics',
  // },
  // {
  //   routeName: '/documentation',
  //   text: 'Documentation',
  // },
];

const linkArrayNamesTeamLeader = [
  {
    routeName: '/dashboard',
    text: 'Dashboard',
    enabled: true,
  },
  {
    text: 'Team Engagement',
    routeName: '/members/team-engagement',
    enabled: true,
  },
  {
    text: 'Time Off Requests',
    submenuLocation: '/pto',
    subRoutes: [
      { text: 'Personal Time Off (PTO)', routeName: '/pto/requests', enabled: true },
      { text: "Team Member's Time Off", routeName: '/pto/members', enabled: true },
    ],
    enabled: true,
  },
];

const linkArrayNamesMember = [
  {
    routeName: '/pto/requests',
    text: 'Time Off Requests',
    enabled: true,
  },
];

const NoOtherWorkspacesText = styled.p`
  margin: 0 11px;
  font-size: 15px;
  white-space: nowrap;
  color: var(--dark-4);
`;

const MenuLink = styled.li`
  ${(props) => !props.enabled && css`
  cursor: disabled;
  pointer-events: none;
  opacity: 0.3;
  `};
`;

function Sidebar({ children }) {
  const {
    activeWorkspace, workspaces, member,
  } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const { handleWorkspaceSelection } = useWorkspaces();

  if (member.isReportingManager && !linkArrayNamesMember.some((link) => link.routeName === '/dashboard')) {
    linkArrayNamesMember.unshift({
      routeName: '/dashboard',
      text: 'Dashboard',
      enabled: true,
    });
  }
  // eslint-disable-next-line no-nested-ternary
  const linkArray = accountTypeLevel[member.accountType] >= accountTypeLevel['Workspace admin']
    ? linkArrayNamesAdmin
    : (accountTypeLevel[member.accountType] === accountTypeLevel['Team Leader'])
      ? linkArrayNamesTeamLeader : linkArrayNamesMember;

  return (
    <>
      <div className="sidenav">
        <div className="user-menu-sidenav">
          <div className="user-menu-avatar">
            <Avatar className="flex-row align-c jstfy-c" src={activeWorkspace.picture || WorkspaceSVG} />
          </div>
          <div>
            <div className="content">
              <h3>Current Workspace</h3>
              <Dropdown
                displayEmpty
                renderValue={() => activeWorkspace?.name}
                setValue={() => { }}
              >
                {workspaces?.length > 1
                  ? workspaces
                    .filter((workspace) => workspace.id !== activeWorkspace?.id)
                    .map((workspace) => (
                      <MenuItem
                        key={workspace.name}
                        onClick={() => handleWorkspaceSelection(workspace)}
                      >
                        {workspace.name}
                      </MenuItem>
                    ))
                  : <NoOtherWorkspacesText>No other workspaces</NoOtherWorkspacesText>}
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="nav-contents">
          { accountTypeLevel[member.accountType] >= accountTypeLevel['Workspace admin']
            && (
            <h3>
              {' '}
              ADMINISTRATION
                {' '}
            </h3>
            )}
          <ul className="admin-nav">
            { linkArray.map((linkInfo) => (linkInfo?.subRoutes
              ? (
                <SubMenu
                  key={linkInfo.text}
                  linkInfo={linkInfo}
                  open={open}
                  setOpen={(info) => setOpen(info)}
                />
              )
              : (
                <MenuLink
                  key={linkInfo.routeName}
                  enabled={linkInfo.enabled}
                  className="link-item"
                >
                  <NavLink
                    to={linkInfo.routeName}
                    activeClassName="active-link"
                    className="link-text"
                  >
                    {linkInfo.text}
                  </NavLink>
                </MenuLink>
              )))}
          </ul>
        </div>
      </div>
      {children}
    </>
  );
}

export { MenuLink, Sidebar };

Sidebar.propTypes = {
  children: PropTypes.elementType,
};
