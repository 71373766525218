import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { InfoIcon } from '../../components/Icon';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #E0E1E6;
  background: #FFF;
  box-shadow: 0px 7px 48px 0px rgba(0, 56, 153, 0.06);
`;

const CardHeader = styled.div`
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: space-between;
  border-bottom: 1px solid #E0E1E6;
  border-radius: 8px 8px 0px 0px;
  background: #F4F6F7;
`;

const CardTitle = styled.h6`
  color: #0E1E40;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  margin-right: 4px;
`;

const CardBody = styled.div`
  padding: 16px 0 8px;
`;

function ReportCard({
  children,
  title,
  info,
  link,
}) {
  return (
    <CardContainer>
      {title && (
        <CardHeader>
          <div className="d-flex align-c">
            <CardTitle>{title}</CardTitle>
            {info && (
              <Tooltip title={info} placement="top" arrow>
                <span className="d-flex">
                  <InfoIcon width="18" height="18" color="#0E1E40" />
                </span>
              </Tooltip>
            )}
          </div>
          { link ? <Link to={link}>View All</Link> : null }
        </CardHeader>
      )}
      <CardBody>
        { children }
      </CardBody>
    </CardContainer>
  );
}

ReportCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  info: PropTypes.string,
  link: PropTypes.string,
};

export default ReportCard;
